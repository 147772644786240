<template>
  <div class="recipe-block__complexity">
    <div class="recipe-block__complexity-title">{{ $t("complexity") }}</div>
    <div class="recipe-block__complexity-amounts">
      <button
        v-for="(diff, index) in difficulty"
        :key="index"
        :class="{ 'is-active': diff.isActive }"
        class="recipe-block__complexity-amount"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecipeDifficulty",
  props: {
    difficultyNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      difficulty: [
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
      ],
    };
  },
  mounted() {
    for (let i = 0; i < this.difficultyNumber; i++) {
      this.difficulty[i].isActive = true;
    }
  },
};
</script>

<style lang="scss" scoped></style>
